/* eslint-disable prefer-const */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */

import { IconCommon, IconLevel } from "components/Helpers/Icons";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { copyText, formatNumber } from "utils/common";
import { LEVEL_INT } from "utils/constant";
import investment from "../../assets/images/investment.png";
import memberImage from "../../assets/images/member.png";
import Layout from "../Partials/Layout";
import Leveling from "./Leveling";
import TreeSystem from "./TreeSystem";

export default function Member() {
  const domain = window.location.origin;
  const { auth, parameter, system, usersGroup } = useContext(GlobalContext);
  const [members, setMembers] = useState(null);
  const [levelProgress, setLevelProgress] = useState(0);

  const getItems = (mapList, parentId, count_progress) => {
    const items = mapList.filter((e) => e.referred_by === parentId);
    items.forEach((el) => {
      console.log(el);
      if (LEVEL_INT[el.count.level] >= LEVEL_INT[parameter.level]) {
        count_progress.count += 1
      }
      const items = getItems(mapList, el.refer_code, count_progress);
      if (items && items != null && items.length > 0) {
        el.nodes = items;
      }
    });
    return items;
  };

  const getMembers = async () => {
    try {
      if (usersGroup) {
        const _members = usersGroup.map((m) => ({
          ...m,
          key: m.refer_code,
          label: (
            <div className="child-tree text-dark-gray dark:text-white flex items-center">
              <IconLevel name={`${String(m.count.level || "member").toLowerCase()}`} width={26} height={26} />
              <span className="ml-3.5">{m.full_name}</span>
              <span className="mx-4">|</span>
              <span className="text-[#FFCC00] mr-1">TSIB </span>
              <span className="text-gray-60">{formatNumber(m.count.count.invest || 0)}</span>

              {/* 🫂$ {formatNumber(m.count.count.revenue_group || 0)} */}
            </div>
          ),
        }));

        const root = _members.find((u) => u.refer_code === auth.refer_code);

        const tree = _members.filter((u) => u.referred_by === auth.refer_code);

        let total_progress = 0;
        tree.forEach((el) => {
          let count_progress = { count: 0 };
          const items = getItems(_members, el.refer_code, count_progress);
          if (items && items != null && items.length > 0) {
            el.nodes = items;
          }
          if (LEVEL_INT[el.count.level] >= LEVEL_INT[parameter.level]) {
            count_progress.count += 1
          }
          if (count_progress.count > 0) total_progress += 1;
        });
        setMembers(tree);
        setLevelProgress(total_progress)
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (usersGroup.length > 0) getMembers();
  }, [usersGroup]);

  const memberCategories = [
    {
      id: "1",
      name: "Your level",
      value: parameter.level || "MEMBER",
      icon: `assets/images/levels/${String(
        parameter.level || "member"
      ).toLowerCase()}.png`,
    },
    {
      id: "2",
      name: "Member",
      value: usersGroup?.length || 0,
      icon: "assets/images/member1.svg",
    },
    {
      id: "3",
      name: "Total sales",
      value: formatNumber(parameter?.count.revenue_group || 0),
      icon: "assets/images/total_sales.svg",
      unit: system.token,
    },
  ];

  const handleCopy = (value) => {
    copyText(value);
    toast.success("Copied");
  };

  return (
    <>
      <Layout>
        <div className="flex lg:flex-row flex-col gap-10 mb-10">
          <div className="lg:w-2/3 bg-blue-9 rounded-[28px]  border border-t-[2px] border-white-12">
            <div className="px-[22px] py-5 bg-blue-dark rounded-[28px]">
              <div className="flex justify-between mb-3">
                <img src={investment} alt="shield" className="w-[45px] h-[45px]" />
                <Link to="/invest" className="rounded-full border border-white-12 px-4 py-2">
                  <span className="text-white font-semibold	">
                    Invest
                  </span>
                </Link>
              </div>
              {/* <div className="flex justify-between">
                <span className="text-white">Total Investment</span>
                <p className="text-yellow-supernova font-medium flex sm:block flex-col items-end">
                  <span className="sm:text-2xl text-xl sm:mr-1">{formatNumber(parameter?.count.revenue_group || 0)}</span>
                  <span className="text-xs">TSIB </span>
                </p>
              </div> */}
            </div>

            <div className="px-[22px] pt-3 pb-[30px]">
              <div className="flex sm:flex-row flex-col justify-between gap-8">
                <span className="lg:max-w-[160px] text-white">
                  Use the below link to invite your friends:
                </span>
                <div className="flex items-center gap-3">
                  <p className="url-share mr-1 text-gray-60 text-lg truncate w-full">{`${domain}/signup?referred_by=${auth?.refer_code}`}</p>
                  <div className="rounded-full bg-[#FCB711] p-3 cursor-pointer">
                    <IconCommon
                      name="copy-dark.svg"
                      width={20}
                      height={20}
                      onClick={(_) =>
                        handleCopy(`${domain}/signup?referred_by=${auth?.refer_code}`)
                      }
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-1/3 bg-blue-9 rounded-[28px] border border-t-[2px] border-white-12">
            <div className="px-[22px] py-5 bg-blue-dark rounded-[28px]">
              <div className="flex justify-between mb-3">
                <img src={memberImage} alt="shield" className="w-[45px] h-[45px]" />
                <IconLevel name={`${String(
                  parameter.level || "member"
                ).toLowerCase()}`} width={45} height={45} className="w-[45px] h-[45px] max-w-full" />

              </div>
              <div className="flex justify-between items-center">
                <span className="text-white">Your level</span>
                <span className="text-white text-2xl">{parameter.level || "MEMBER"}</span>
              </div>
            </div>

            <div className="px-[22px] pt-3 pb-[30px] mt-4">
              <div className="flex justify-between items-center">
                <span className="text-white">Members</span>
                <span className="text-white text-[22px]">{usersGroup?.length || 0}</span>
              </div>
            </div>
          </div>
        </div>

        <TreeSystem members={members} />
        <Leveling members={members} levelProgress={levelProgress} />
      </Layout>
    </>
  );
}
